import { transform } from "framer-motion";
import React, { useEffect, useState } from "react";
// import { motion } from "framer-motion";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";
// import { useDropzone } from "react-dropzone";
// import { FaCamera } from "react-icons/fa";
import { FaSearchPlus } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";


import $ from 'jquery'; 
const key = 1;
const Moodboard = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');

  useEffect(() => {
    $('input:checkbox').on('change', function () {
      setPage(1);
      var resultUrl = "";
      var url_all = "https://alnoormdf.com/api/products?";
      var mc_array = [];
      var sc_array = [];
      var api = "https://alnoormdf.com/api/products?";
      mc_array = [];
      sc_array = [];
      $("input:checkbox:checked").each(function () {
        if ($(this).hasClass('m-cat')) {
          mc_array.push($(this).attr('rel'));
        }
        if ($(this).hasClass('s-cat')) {
          sc_array.push($(this).attr('rel'));
        }
      });

      resultUrl = api + "c_id=" + mc_array.join(",") + "&sc_id=" + sc_array.join(",")
      //alert(url);

      setUrl(resultUrl);

      //console.log(resultUrl);

      if (resultUrl == "https://alnoormdf.com/api/products?c_id=&sc_id=") {
        resultUrl = "https://alnoormdf.com/api/products?";
      }

      const getallproducts = async () => {
        const res = await axios.get(`${resultUrl}&page=${page}`);
        if (res.status === 200) {
          console.log(`${resultUrl}&page=${page}`);
          console.log('Products Data:');
          console.log(res.data.data);
          setallproducts((prevProducts) => [...prevProducts, ...res.data.data]);
        }
      };

      const loadMore = async () => {
        setPage((page) => page + 1);
        getallproducts();
      };

      $('.decorList').empty();
      loadMore();
      if ($(window).width() < 768) {
        $('a').removeAttr('target');
      }
    });

    $(".tactile").on('click', function () {
      $(this).find('img').toggleClass('invert');
      $(".tactile-sf").toggleClass('show');
    });
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const [allproducts, setallproducts] = useState([]);
  const [allcategories, setallcategories] = useState([]);
  const [url, setUrl] = useState("https://alnoormdf.com/api/products?");

  useEffect(() => {
    loadMore();
    getallcategories();
    jQueryFilterTags();
    filterLink();

    if ($(window).width() < 768) {
      $('a').removeAttr('target');
    }
  }, []);

  const getallproducts = async () => {
    const res = await axios.get(`${url}&page=${page}`);
    if (res.status === 200) {
      console.log(`${url}&page=${page}`);
      console.log('Products Data:');
      console.log(res.data.data);
      setallproducts((prevProducts) => [...prevProducts, ...res.data.data]);
      if ($(window).width() < 768) {
        $('a').removeAttr('target');
      }
    }
  };

  const loadMore = async () => {
    setPage((page) => page + 1);
    getallproducts();
  };

  const getallcategories = async () => {
    const res = await axios.get(`https://alnoormdf.com/alnoor/all-categories`);
    if (res.status === 200) {
      setallcategories(res.data.data);

      if ($(window).width() < 768) {
        $('a').removeAttr('target');
      }
    }
  };


  const jQueryFilterTags = () => {
    let timeoutId;

    $("#filter").keyup(function () {
      clearTimeout(timeoutId);

      const filter = $(this).val();
      setPage(1);

      const mc_array = [];
      const sc_array = [];

      $("input:checkbox:checked").each(function () {
        if ($(this).hasClass('m-cat')) {
          mc_array.push($(this).attr('rel'));
        }
        if ($(this).hasClass('s-cat')) {
          sc_array.push($(this).attr('rel'));
        }
      });

      const resultUrl = `https://alnoormdf.com/api/products?c_id=${mc_array.join(",")}&sc_id=${sc_array.join(",")}&q=${filter}`;

      $('.decorList').empty();
      $('.decorList').append('<div id="dload" class="text-center">Searching</div>');

      timeoutId = setTimeout(function () {
        $('#dload').remove();
        setUrl(resultUrl);
        const getallproducts = async () => {
          const res = await axios.get(`${resultUrl}&page=${page}`);
          if (res.status === 200) {
            console.log(`${resultUrl}&page=${page}`);
            console.log('Products Data:');
            console.log(res.data.data);
            setallproducts((prevProducts) => [...prevProducts, ...res.data.data]);
          }
        };

        setPage((page) => page + 1);
        getallproducts();
      }, 1000);
    });
  };


  const filterLink = async () => {
    if (filter === 'select') {
      setTimeout(function () {
        $(".select").find('input[type="checkbox"]').click();
      }, 500);
    }

    if (filter === 'geodesic') {
      setTimeout(function () {
        $(".geodesic").find('input[type="checkbox"]').click();
      }, 500);
    }
    if (filter === 'tactile') {
      $(".tactile").click();
    }
  };

  useEffect(() => {
    // Update Page title tag  
    document.title = "Decor Library | Al-Noor";

    // Select the meta tag with a name attribute of "property"
    const socialPageTitle = document.querySelector('meta[property="og:title"]');

    // Update the content attribute of the meta tag
    socialPageTitle.setAttribute('content', "Al-Noor | Decor Library");

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "Find the best suited decor as per your requirements from among the vast collection list of our decors.");
  }, []);

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (event) => {
    event.preventDefault(); // Prevent the default form submission
    // Your search logic here
    console.log('Search query:', searchQuery);
  };

  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [modalImages, setModalImages] = useState([]);

  // const openModal = () => setShowModal(true); // Open modal
  const openModal = (images) => {
    setModalImages(images);
    setShowModal(true);
  };
  const closeModal = () => setShowModal(false); // Close modal

  //  =================================== drag n drop ========================================
  const [twoBoxImages, setTwoBoxImages] = useState([null, null]);
  const [fourBoxImages, setFourBoxImages] = useState([null, null, null, null]);

  const handleDrop = (e, index, boxType) => {
    e.preventDefault();
    const imageUrl = e.dataTransfer.getData("text/plain");

    console.log("Dropped image:", imageUrl); // Debugging log

    if (!imageUrl) return; // Ensure there's valid data

    if (boxType === "two") {
      setTwoBoxImages((prev) => {
        const newImages = [...prev];
        newImages[index] = imageUrl;
        return newImages;
      });
    } else if (boxType === "four") {
      setFourBoxImages((prev) => {
        const newImages = [...prev];
        newImages[index] = imageUrl;
        return newImages;
      });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragStart = (e, imageUrl) => {
    console.log("Dragging image:", imageUrl);
    e.dataTransfer.setData("text/plain", imageUrl);
  };



  const [showFullScreen, setShowFullScreen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openFullScreen = (image) => {
    setSelectedImage(image);
    setShowFullScreen(true);
  };

  const closeFullScreen = () => {
    setShowFullScreen(false);
    setSelectedImage(null);
  };

  const removeImage = (index) => {
    setModalImages((prevImages) =>
      prevImages.map((img, i) => (i === index ? null : img))
    );
  };






  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", height: "100vh", width: "100%" }}>

        {/* Sticky Navbar */}
        {/* <div style={{ height: "60px", width: "100%", backgroundColor: "white", display: "flex", alignItems: "center", justifyContent: "center", position: "sticky", top: "0", zIndex: "1000", boxShadow: "0px 4px 6px rgba(0,0,0,0.1)", marginBottom: "0px" }}>
          <p style={{ fontSize: "22px", fontWeight: "bold", fontFamily: "AvenirRoman", color: "#4b4b4b", textAlign: "center", marginBottom: "0px" }}>
            Mood Board
          </p>
        </div> */}

        <div
          style={{
            height: "60px",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Aligns items properly
            position: "sticky",
            top: "0",
            zIndex: "1000",
            boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            padding: "0 20px", // Adds spacing from edges
          }}
        >
          {/* Logo on the left */}
          <a href="/">
            <img
              className="mainlogo"
              src="/images/logo.webp"
              alt="Alnoor"
              style={{ height: "50px", objectFit: "contain" }} // Adjusts size
            />
          </a>

          {/* Title in the center */}
          <p
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              fontFamily: "AvenirRoman",
              color: "#4b4b4b",
              textAlign: "center",
              flex: "1", // Makes the title take up remaining space
              margin: "0",
            }}
          >
            Mood Board
          </p>
        </div>


        <div style={{ flex: "1", overflow: "auto", backgroundColor: "#f0f0f0", padding: "10px" }}>
          <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", marginBottom: "0px" }}>
            <div className="decorslist-search-categories-moodboard" style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "0px" }}>
              <form id="live-search" onSubmit={handleSearch} className="styled" method="post">
                <div>
                  <input type="text" id="filter" placeholder="Search by code....." style={{ width: "100%", height: "38px", border: "2px solid black", borderRadius: "12px", padding: "15px", paddingTop: "14.5px" }} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                </div>
                <div className="d-flex" style={{ width: "100%", marginLeft: "auto", marginRight: "auto", marginTop: "15px" }}>
                  <div className="tags" style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div className="form-check form-check-inline geodesic">
                      <input className="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox24" rel="26" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option1" />
                      <label className="form-check-label" htmlFor="inlineCheckbox24" style={{ fontSize: "14px", fontWeight: "500" }}>Geodesic</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox1" rel="16" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option1" />
                      <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: "14px", fontWeight: "500" }}>S Gloss</label>
                    </div>

                    <div className="form-check form-check-inline h-gloss">
                      <input className="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox3" rel="18" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option1" />
                      <label className="form-check-label" htmlFor="inlineCheckbox3" style={{ fontSize: "14px", fontWeight: "500" }}>High Gloss</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox4" rel="19" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option2" />
                      <label className="form-check-label" htmlFor="inlineCheckbox4" style={{ fontSize: "14px", fontWeight: "500" }}>Natural Matte</label>
                    </div>

                    <div className="form-check form-check-inline select">
                      <input className="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox5" rel="20" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option1" />
                      <label className="form-check-label" htmlFor="inlineCheckbox5" style={{ fontSize: "14px", fontWeight: "500" }}>Select</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox6" rel="21" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option2" />
                      <label className="form-check-label" htmlFor="inlineCheckbox6" style={{ fontSize: "14px", fontWeight: "500" }}>Wood Grain</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input mt-1 m-cat" type="checkbox" id="inlineCheckbox7" rel="22" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option1" />
                      <label className="form-check-label" htmlFor="inlineCheckbox7" style={{ fontSize: "14px", fontWeight: "500" }}>Solid</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input mt-1 s-cat" type="checkbox" id="5" rel="5" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option1" />
                      <label className="form-check-label" htmlFor="5" style={{ fontSize: "14px", fontWeight: "500" }}>Natural Veneer</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input mt-1 s-cat" type="checkbox" id="6" rel="6" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option1" />
                      <label className="form-check-label" htmlFor="6" style={{ fontSize: "14px", fontWeight: "500" }}>Brushed Oak</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input mt-1 s-cat" type="checkbox" id="7" rel="7" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option1" />
                      <label className="form-check-label" htmlFor="7" style={{ fontSize: "14px", fontWeight: "500" }}>Vintage</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input mt-1 s-cat" type="checkbox" id="8" rel="8" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option1" />
                      <label className="form-check-label" htmlFor="8" style={{ fontSize: "14px", fontWeight: "500" }}>Calcite</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input mt-1 s-cat" type="checkbox" id="9" rel="9" style={{ marginRight: "0px", width: "14px", height: "14px", transform: "scale(0.8)" }} value="option1" />
                      <label className="form-check-label" htmlFor="9" style={{ fontSize: "14px", fontWeight: "500" }}>Textile</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* <ul className="decorList text-center">
            {allproducts.map((product, index) => (
              <li key={index} style={{ position: "relative" }} data-id={product.product_id} data-rank={product.row_reorder}>
                <div className="decore-tile-container-moodboard" draggable="true" onDragStart={(e) => handleDragStart(e, `https://alnoormdf.com/${product.thumbnail_image}`)}>
                  <a href={"/decorsdetail/" + product.product_slug} target="_blank" rel="noopener noreferrer">
                    <img
                      className="decore-tile-image"
                      src={`https://alnoormdf.com/${product.thumbnail_image}`}
                      alt={product.product_name}
                    />
                  </a>
                  <div className="decore-tile-label">
                    <p className="decore-tile-label-text-moodboard">
                      <a href={"/decorsdetail/" + product.product_slug} target="_blank" rel="noopener noreferrer">
                        {product.product_name}
                      </a>
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul> */}
          <ul className="decorList text-center" style={{ paddingBottom: "30px" }}>
            {allproducts.map((product, index) => (
              <li key={index} style={{ position: "relative" }} data-id={product.product_id} data-rank={product.row_reorder}>
                <div
                  className="decore-tile-container-moodboard"
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, `https://alnoormdf.com/${product.thumbnail_image}`)}
                  style={{ position: "relative" }}
                >
                  {/* Magnifying Glass Icon */}
                  <FaSearchPlus
                    className="magnify-icon"
                    onClick={() => openFullScreen(`https://alnoormdf.com/${product.thumbnail_image}`)}
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      fontSize: "22px",
                      color: "#fff",
                      background: "rgba(0,0,0,0.6)",
                      padding: "5px",
                      borderRadius: "50%",
                      cursor: "pointer",
                      zIndex: "10",
                    }}
                  />

                  <a href={"/decorsdetail/" + product.product_slug} target="_blank" rel="noopener noreferrer">
                    <img
                      className="decore-tile-image"
                      src={`https://alnoormdf.com/${product.thumbnail_image}`}
                      alt={product.product_name}
                    />
                  </a>
                  <div className="decore-tile-label">
                    <p className="decore-tile-label-text-moodboard">
                      <a href={"/decorsdetail/" + product.product_slug} target="_blank" rel="noopener noreferrer">
                        {product.product_name}
                      </a>
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          {/* Full-Screen Image Modal */}
          {showFullScreen && (
            <div className="fullscreen-modal" onClick={closeFullScreen}
              style={{ position: "fixed", top: "0", left: "0", width: "100vw", height: "100vh", background: "rgba(0, 0, 0, 0.8)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "1200", cursor: "pointer" }}>
              <img src={selectedImage} alt="Full Screen" style={{ maxWidth: "90%", maxHeight: "90%", borderRadius: "8px" }} />
            </div>
          )}

        </div>

        <div style={{ height: "60px", width: "100%", backgroundColor: "#fff", display: "flex", alignItems: "center", justifyContent: "center", position: "fixed", bottom: "0", left: "0", zIndex: "1100", boxShadow: "0px -4px 6px rgba(0,0,0,0.1)", }}>
          {/* Drop Zones */}
          <div style={{ display: "flex", gap: "20px", backgroundColor: "white" }}>
            {/* 2-Slot Box */}
            <div onClick={() => openModal(twoBoxImages)} style={{ width: "50px", height: "50px", display: "grid", gridTemplateColumns: "1fr 1fr", border: "1px solid black", cursor: "pointer" }}>
              {twoBoxImages.map((image, index) => (
                <div key={index} onDrop={(e) => handleDrop(e, index, "two")} onDragOver={handleDragOver} style={{
                  width: "100%", height: "100%", border: "1px solid gray", backgroundSize: "cover", backgroundImage: image ? `url(${image})` : "none",
                }}>
                </div>
              ))}
            </div>

            {/* 4-Slot Box */}
            <div onClick={() => openModal(fourBoxImages)} style={{ width: "50px", height: "50px", display: "grid", gridTemplateColumns: "1fr 1fr", gridTemplateRows: "1fr 1fr", border: "1px solid black", cursor: "pointer" }}>
              {fourBoxImages.map((image, index) => (
                <div key={index} onDrop={(e) => handleDrop(e, index, "four")} onDragOver={handleDragOver} style={{ width: "100%", height: "100%", border: "1px solid gray", backgroundSize: "cover", backgroundImage: image ? `url(${image})` : "none", }}>
                </div>
              ))}
            </div>
          </div>
        </div>


        {/* Modal */}

        {/* {showModal && (
          <div
            className="modal fade show slide-up-modal"
            tabIndex="-1"
            aria-labelledby="compareModalLabel"
            aria-hidden="true"
            style={{
              display: "block",
              background: "rgba(0,0,0,0.5)",
              position: "fixed",
              inset: "0",
              zIndex: "1150",
            }}
          >
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Comparison Modal</h5>
                  <button type="button" className="btn-close" onClick={closeModal}></button>
                </div>
                <div
                  className="modal-body"
                  style={{
                    display: "grid",
                    gridTemplateColumns: modalImages.length === 4 ? "repeat(2, 1fr)" : `repeat(${modalImages.length}, 1fr)`,
                    gridTemplateRows: modalImages.length === 4 ? "repeat(2, 1fr)" : "auto",
                    height: "calc(100vh - 120px)",
                  }}
                >
                  {modalImages.map((image, index) => (
                    <div
                      key={index}
                      style={{
                        border: "1px solid gray",
                        backgroundSize: "cover",
                        backgroundImage: image ? `url(${image})` : "none",
                        height: "100%",
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
 */}
        {showModal && (
          <div
            className="modal fade show slide-up-modal"
            tabIndex="-1"
            aria-labelledby="compareModalLabel"
            aria-hidden="true"
            style={{
              display: "block",
              background: "rgba(0,0,0,0.5)",
              position: "fixed",
              inset: "0",
              zIndex: "1150",
            }}
          >
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Comparison Modal</h5>
                  <button type="button" className="btn-close" onClick={closeModal}></button>
                </div>
                <div
                  className="modal-body"
                  style={{
                    display: "grid",
                    gridTemplateColumns: modalImages.length === 4 ? "repeat(2, 1fr)" : `repeat(${modalImages.length}, 1fr)`,
                    gridTemplateRows: modalImages.length === 4 ? "repeat(2, 1fr)" : "auto",
                    height: "calc(100vh - 120px)",
                  }}
                >
                  {modalImages.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", border: "1px solid gray", backgroundSize: "contain", backgroundImage: image ? `url(${image})` : "none", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", objectFit: "contain", backgroundColor: image ? "transparent" : "#f0f0f0" }} >
                      {image && (
                        <FaTrash onClick={() => removeImage(index)}
                          style={{ position: "absolute", top: "10px", right: "10px", fontSize: "28px", color: "#fff", background: "rgba(0, 0, 0, 0.7)", padding: "5px", borderRadius: "50%", cursor: "pointer", zIndex: "10" }} />
                      )}
                      {!image && <p style={{ color: "#999" }}>No Image</p>}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

};

export default Moodboard;
